.App {
  max-width: 1100px;
  margin: 0 auto;
  background-color: var(--color-header-background);
}

.content {
  display: flex;
  justify-content: center;
  gap: 30px; /* Consistent spacing between columns */
  padding: 0 20px;
  margin: 0 auto; /* Center the content */
}

/* media query to apply styles at < 768px */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    gap: 0;
  }
}

.theme-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  color: var(--color-toggle-button-text);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  transition: background 0.3s, color 0.3s;
  z-index: 1000; /* Ensure the button is above other elements */
}

.theme-toggle-button:hover {
  background: transparent;
}

.headline-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .main-headline-container .headline-image {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .main-headline-container .headline-image {
    width: 60%;
  }
}
