.headline {
  margin-bottom: 35px; /* Spacing between headlines */
  transition: color 0.3s;
}

.headline a {
  font-size: 18px;
  line-height: 1.2;
  transition: color 0.3s;
}

.main-headline {
  text-align: center;
  transition: color 0.3s;
}

.main-headline a {
  font-size: 26px;
  font-weight: bold;
  transition: color 0.3s;
}

/* Responsive Font Sizes */
@media (max-width: 768px) {
  .headline a {
    font-size: 16px;
  }

  .main-headline a {
    font-size: 22px;
  }
}
