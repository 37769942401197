.header {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-header-background);
  color: var(--color-header-text);
  transition: background-color 0.3s, color 0.3s;
}

.header h1 {
  font-size: 65px;
  font-weight: bold;
  letter-spacing: 10px;
  word-spacing: 15px;
  margin: 0;
  line-height: 1;
  color: var(--color-header-text);
  transition: color 0.3s;
}
